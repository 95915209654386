/* website: 2849-gm-nicol-auto
 * created at 2019-09-30 16:00 by fbbreard
 */

// Import all makes and organization styles files
@import "../templates/makes/gmc-preferred.scss";
@import "../utils/icons.scss";

.widget-sr {
    &.dealer__nicol-auto-inc {
        .HeaderMessage .widget-vehicle-quote-btn-secondary.sr-button-outline-2.is-alternate{
            background: #CC0000;
            border-color: #cc0000 !important;
        }
    }
}